.get-in-touch > p {
    margin: 0px 0px 50px 0px;
    color: var(--color-primary-light);
    line-height: 1.3;
    font-size: clamp(20px, 8vw, 30px);
}

.linkedin-iframe {
    z-index: 1;
}

.get-in-touch > p {
    padding: 10px;
    margin: 0px;
}

a.contact-box {
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    color: var(--color-primary-light);
    
    background: linear-gradient(63deg, rgba(33, 126, 189, 0.8) 0%, rgba(59, 92, 186, 0.8) 100%);

    padding: 25px;
    margin: 25px;
    
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px  rgba(255,255,255,0.2);

    transition: all 1s;

    text-decoration: none;
}

a.contact-box > svg {
    margin-bottom: 10px;
}

a.contact-box:hover {
    box-shadow: 0px 0px 10px 5px  rgba(255,255,255,0.5);
    transition: all 0.7s;
}

.contact-box-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}