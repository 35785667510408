.project-item {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: start;
    align-items: start;

    
    padding: 140px 0px;
}

.project-image-container {
    
    
    position: relative;

}

.project-image-container > a > img ,
.project-image-container > img {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px  rgba(255,255,255,0.3);
}


.project-text-container {
    
    background: linear-gradient(63deg, rgba(33, 126, 189, 0.8) 0%, rgba(59, 92, 186, 0.8) 100%);
    

    position: relative;

    margin-top: -5%;

    padding: 25px;
    
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px  rgba(255,255,255,0.5);
    
    
    /*
    
    background-color: #ffffffa0;
    
    border: 2px solid #ffffffd0;

    color: var(--color-dark);

    */
}

.project-image {
    width: 100%;
}

.project-title-container {
    color: var(--color-primary);
    line-height: 1.1;
    font-size: clamp(20px, 5vw, 45px);
    
    padding-bottom: 20px;
}

.project-description-container {
    font-family: 'Futura Medium';
    color: var(--color-primary);
    line-height: 1.3;
    font-size: clamp(13px, 3vw, 20px);

}

.project-item.image-left > .project-text-container
{
    text-align: right;
}

.project-item.image-right > .project-text-container
{
    text-align: left;
}

.project-item.image-left > .project-text-container,
.project-item.image-right > .project-image-container
{
    grid-column: 5 / -1;
    grid-area: 1 / 6 / -1 / -1;
}

.project-item.image-left > .project-image-container,
.project-item.image-right > .project-text-container
{
    grid-column: 1 / 8;
    grid-area: 1 / 1 / -1 / 8;
}

.project-tags-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.project-links-container {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
}

.project-item.image-right > .project-text-container > .project-links-container {
    justify-content: flex-start;
}

.project-item.image-left > .project-text-container > .project-links-container {
    justify-content: flex-end;
}


@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .project-text-container {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
} 
