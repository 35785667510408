@font-face {
    font-family: 'Futura Bold';
    src: url('fonts/Futura Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Book';
    src: url('fonts/Futura Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Extra Black';
    src: url('fonts/Futura Extra Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Heavy';
    src: url('fonts/Futura Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Medium';
    src: url('fonts/Futura Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Light';
    src: url('fonts/Futura Light.ttf') format('truetype');
}

.futura-bold {
    font-family: 'Futura Bold';
}

.futura-book {
    font-family: 'Futura Book';
}

.futura-extra-black {
    font-family: 'Futura Extra Black';
}

.futura-heavy {
    font-family: 'Futura Heavy';
}

.futura-medium {
    font-family: 'Futura Medium';
}

.futura-light {
    font-family: 'Futura Light';
}

/*
@font-face {
    font-family: 'League Spartan Bold';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Semi Bold';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Extra Bold';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Black';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Extra Light';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Light';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Medium';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'League Spartan Regular';
    font-weight: 400;
    font-stretch: 100%;
    src: url('fonts/LeagueSpartan-Regular.ttf') format('truetype');
}


.spartan-medium {
    font-family: 'League Spartan Medium';
}

.spartan-regular {
    font-family: 'League Spartan Regular';
}

.spartan-semibold {
    font-family: 'League Spartan SemiBold';
}

.spartan-light {
    font-family: 'League Spartan Light';
}

.spartan-extralight {
    font-family: 'League Spartan Extralight';
}

.spartan-extrabold {
    font-family: 'League Spartan Extrabold';
}

.spartan-bold {
    font-family: 'League Spartan Bold';
}

.spartan-black {
    font-family: 'League Spartan Black';
}
*/

/* cyrillic-ext */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


/* cyrillic */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* greek-ext */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}


/* greek */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}


/* hebrew */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}


/* vietnamese */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


.white {
    color: white;
}

.bigger {
    font-size: 30px;
}





* {
    font-family: "Futura Book";
}

.font-light {
    font-family: 'Futura Light';
}

.font-book {
    font-family: 'Futura Book';
}

.font-medium {
    font-family: 'Futura Medium';
}

.font-bold {
    font-family: 'Futura Heavy';
}

.font-bold-thin {
    font-family: 'Futura Bold';
}

.font-huge {
    font-family: 'Futura Extra Black';
}




