.badge {
    margin: 2px;
    padding: 3px 5px;
    font-family: 'Futura Light';
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 8px;
    background-color: #ffffff16;
}

.image-left > .project-text-container > .project-tags-container {
    justify-content: flex-end;
}

.image-right > .project-text-container > .project-tags-container {
    justify-content: flex-start;
}