/**
   * ----------------------------------------
   * animation fade-out
   * ----------------------------------------
   */

.fade-in {
    opacity: 1;
    -webkit-animation: fade-in 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/**
   * ----------------------------------------
   * animation fade-in
   * ----------------------------------------
   */

.fade-out {
    opacity: 0;
    -webkit-animation: fade-out 600ms ease-out both;
    animation: fade-out 600ms ease-out both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}