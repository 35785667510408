#HeaderContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;

    width: auto;
    height: 100%;

    z-index: 101;

    
    display: flex;
    align-items: center;
    padding-left: 12%;
    padding-right: 12%;
    
}

.HeaderContentContainer {

    left: 13%;
}

.text-homepage-my-name-is {
    /*font-weight: 600;*/
    margin: 0px;

    color: var(--color-secondary);
    /*letter-spacing: 0.8px;*/
    font-size: clamp(14px, 5vw, 16px);
}

.text-homepage-name {
    /*font-weight: 600;*/
    margin: 0px;

    color: var(--color-primary-light);
    font-size: clamp(30px, 8vw, 75px);
    line-height: 1.1;
}


.text-homepage-what-i-do {
    /*font-weight: 600;*/
    margin: 0px;

    color: var(--color-primary);
    
    line-height: 1.1;
    font-size: clamp(30px, 8vw, 70px);
}

.text-homepage-what-i-do-description {
    /*font-weight: 500;*/
    margin: 0px;
    margin-top: 20px;

    width: 71%;
    max-width: 670px;
    min-width: 250px;

    color: var(--color-secondary-light);
    font-size: clamp(14px, 3vw, 20px);

}