
:root {
    --button-with-icon-height: 40px;
    --button-height: 16px;
    --button-border-radius: 16px;
}

.flexible-navbar{
    position: fixed;

    right: 0px;
    top: 0px;

    padding: 15px;
    margin: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;

    z-index: 101;
}


.flexible-item {
    opacity: 1;
    margin: 10px 20px;
    padding: 8px 30px;
    text-decoration: none;
    
    height: fit-content;

    line-height: var(--button-height);
    border-radius: var(--button-border-radius);
    
    border: 2px solid rgba(255,255,255, 0.7);
    
    background-color: rgba(255,255,255,0.2);/*var(--background-color);*/
    color: rgba(255,255,255, 0.8);
    box-shadow: 0 2px 12px rgba(255,255,255,0.3);


    transition: color 0ms,
                background-color 400ms,
                color 400ms,
                box-shadow 0.3s ease-in-out;

}


.flexible-item:hover,
.flexible-item.active {
    background-color: rgba(255,255,255, 0.8);
    color: var(--background-color);
    box-shadow: 0 1px 12px rgba(255,255,255,0.8);
}


.flexible-item-icon{
    display: none;
    text-align: center;
}


.flexible-item-text{
    text-align: center;
}




.flexible-navbar-vertical > .flexible-item > .flexible-item-icon {
    display: block;
}

.flexible-navbar-vertical {

    position: fixed;
    right: 0px;
    top: 0px;

    height: 100vh;
    
    padding: 25vh 15px;

    margin: 0px;

    display: flex;
    list-style-type: none;
    z-index: 100;
    background-color: rgba(255,255,255, 0.6);
    flex-direction: column;

}

.flexible-navbar-vertical > .flexible-item 
{
    margin: auto;
    padding: 10px;
    border-radius: 8px;
    border: none;
    box-shadow: none;

    
    background-color: rgba(0,0,0,0);/*var(--background-color);*/
    color: var(--color-dark);
    box-shadow: none;
}

.hide-on-homepage {
    opacity: 0;
    display: none;
}


.flexible-navbar-vertical > .flexible-item:hover
{
    box-shadow: 0 1px 12px rgba(255,255,255,0.8);
}

.flexible-navbar-vertical > .flexible-item:hover,
.flexible-navbar-vertical > .flexible-item.active {
    background-color: rgba(255,255,255, 0.8);
    color: var(--background-color);
}
