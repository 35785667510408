.section-title {
    color: var(--color-primary);
    line-height: 1.1;
    font-size: clamp(30px, 8vw, 55px);
}

.section-title-container {
    text-align: right;
    margin: 0px 20%;
}

.section-container {
    padding: 60px 5%;
    width: 90%;
    width: 100%;
}

.section {
    padding: 55px 0px;
}