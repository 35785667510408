
#particles-background {

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  width: 100vw;
  height: 100vh;

  z-index: -1;
  background: linear-gradient(63deg, var(--start-color-bg) 0%, var(--end-color-bg) 100%);
  background-attachment: fixed;
}

#particles-background > canvas {
  z-index: -1;
}
