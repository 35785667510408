html {
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, h6 {
  padding: 0px;
  margin: 0px;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0px;
}

/* Optional: show position indicator in red */

::-webkit-scrollbar-thumb {
  background: #ffffff;
}

:root {
  --start-color-bg: #543ab7;
  --end-color-bg: #00acc1;
  --background-color: #188cbe;
  --color-dark: #091353;

  /*X --color-primary-dark: #bdecf9;*/
  --color-primary: #d0f2fb;
  --color-primary-light: #ece9ff;

  --color-secondary: #bdecf9;
  --color-secondary-light: #d0f2fb;
  /*--color-secondary-light: #ece9ff;*/

  --color-accent-dark: #C996CC;
  --color-accent: #C996CC;
  --color-accent-light: #C996CC;

  --color-text-dark: #333333;
  --color-text: #444444;
  --color-text-light: #555555;
}

.icon-link {

  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid rgba(255,255,255, 0.7);

}

.btn-light {
  opacity: 1;
  padding: 8px 20px;
  text-decoration: none;
  height: -moz-fit-content;
  height: fit-content;
  line-height: var(--button-height);
  border-radius: var(--button-border-radius);
  border: 2px solid rgba(255,255,255, 0.7);
  background-color: rgba(255,255,255,0.2);
  color: rgba(255,255,255, 0.8);
  box-shadow: 0 2px 12px rgb(255 255 255 / 30%);
  transition: color 0ms, background-color 400ms, color 400ms, box-shadow 0.3s ease-in-out;
}

.btn-light:hover, .btn-light.active {
  background-color: rgba(255,255,255, 0.8);
  color: var(--start-color-bg);
  box-shadow: 0 1px 12px rgb(255 255 255 / 80%);
}

noscript {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: white;
  font-size: clamp(18px, 3vw, 30px);
}